import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import type {
  AllLayoutsType,
  UserType,
  LabelType,
} from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  PublicRoute,
  ROUTES,
  LoadingIndicator,
  isBrowser,
  DATA_LAYER_SCRIPT,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  data: {
    prismic: {
      allLayouts: AllLayoutsType,
      allCookie_policy_pages: {|
        title: Array<LabelType>,
      |},
    },
  },
  pageContext: {
    lang: string,
  },
|}

const CookieDeclarationPage = (props: Props) => {
  useEffect(() => {
    const scriptTag = document.createElement("script")
    const mainWrapper = document.getElementById("cookieConsentWrapper")

    scriptTag.src =
      "https://consent.cookiebot.com/aadef9b5-2bd2-4822-ad7f-1378d076b868/cd.js"
    scriptTag.id = "CookieDeclaration"
    scriptTag.type = "text/javascript"

    mainWrapper.appendChild(scriptTag)
    return () => {
      mainWrapper.removeChild(scriptTag)
    }
  }, [])

  const scriptsCookieDeclarationPage = [DATA_LAYER_SCRIPT]

  const { pageContext, data } = props

  if (!data) return null

  const lang = pageContext.unPublishedLang || pageContext.lang
  const { prismicLayout = {}, prismicCookieDeclarationPage = {} } = data
  const pageContent = prismicCookieDeclarationPage.data || {}

  const renderCookieDeclarationPage = ({ user, checkingSession }: UserType) => (
    <Layout
      data={prismicLayout.data}
      lang={lang}
      user={user}
      isLoading={checkingSession}
    >
      {renderSEO(
        pageContent,
        ROUTES(lang).COOKIE_DECLARATION,
        lang,
        scriptsCookieDeclarationPage
      )}
      {isBrowser && (
        <React.Suspense fallback={<LoadingIndicator />}>
          <div
            className="max-w-5xl mx-auto my-30 px-30"
            id="cookieConsentWrapper"
          >
            <h2 className="uppercase text-center text-9xl font-primary my-20">
              {pageContent.title.text}
            </h2>
          </div>
        </React.Suspense>
      )}
    </Layout>
  )

  return (
    <PublicRoute
      component={renderCookieDeclarationPage}
      {...props}
      lang={lang}
    />
  )
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      ...LayoutPublicPage
    }
    prismicCookieDeclarationPage(lang: { eq: $lang }) {
      data {
        title {
          text
          raw
        }
      }
    }
  }
`

export default withPreview(CookieDeclarationPage)
